import Main from './pages/Main.jsx';
import './app.scss';

function App() {
  return (
    <Main />
  );
}

export default App;
